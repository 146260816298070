import { Lead } from "../../types/leads.type";

export enum CommonActionTypes {
    IS_SIDEBAR_OPEN = 'IS_SIDEBAR_OPEN',
}

interface sidebarToggleAction {
    type: CommonActionTypes.IS_SIDEBAR_OPEN;
    payload: boolean;
}

// interface removeLeadAction {
//     type: CommonActionTypes.REMOVE_LEAD;
//     payload: boolean;
// }

export type CommonActions = sidebarToggleAction;