import { useState, useEffect } from 'react';
import './loader.scss';
import axiosInstance from "../../../http-common";

export const Loader = () => {
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        const reqInterceptor = axiosInstance.interceptors.request.use(function (config) {
            setLoading(true);
            return config;
        }, function (error) {
            setLoading(false);
            return Promise.reject(error);
        });
        const resInterceptor = axiosInstance.interceptors.response.use(function (response) {
            setLoading(false);
            return response;
        }, function (error) {
            setLoading(false);
            return Promise.reject(error);
        });
        return () => {
            axiosInstance.interceptors.request.eject(reqInterceptor);
            axiosInstance.interceptors.response.eject(resInterceptor);
        };
    }, []);

    return (
        loading ?
            <div className="loader-wrapper">
                <div className="loader">
                    <div className="loader-shadow"></div>
                    <div className="loader-box"></div>
                </div>
            </div>
        : null
    )
}