import { Reducer } from "@reduxjs/toolkit";
import { CommonActions, CommonActionTypes } from "./actions";

export interface CommonState {
    isSidebarOpen: boolean;
}

const initialState: CommonState = {
    isSidebarOpen: true
}

export const commonReducer: Reducer<CommonState, any> = (state: CommonState = initialState, action: CommonActions): CommonState => {
    switch(action.type) {
        case CommonActionTypes.IS_SIDEBAR_OPEN:
            return {
                ...state,
                isSidebarOpen: action.payload 
            } 
        default: 
            return state;
    }
}