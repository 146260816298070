import { Reducer } from "@reduxjs/toolkit";
import { Lead } from "../../types/leads.type";
import { LeadActions, LeadActionTypes } from "./actions";

export interface LeadState {
    lead: Lead | null;
}

const initialState: LeadState = {
    lead: null
}

export const leadReducer: Reducer<LeadState, any> = (state: LeadState = initialState, action: LeadActions): LeadState => {
    switch(action.type) {
        case LeadActionTypes.STORE_LEAD:
            return {
                ...state,
                lead: action.payload 
            } 
        case LeadActionTypes.REMOVE_LEAD:
            return {
                ...state,
                lead: null 
            } 
        default: 
            return state;
    }
}