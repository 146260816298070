import { LoginPayload } from "../../types/auth.type";

export enum AuthActionTypes {
    LOGIN_PENDING = 'LOGIN_PENDING',
    LOGIN_SUCCESS = 'LOGIN_SUCCESS',
    LOGIN_FAIL = 'LOGIN_FAIL',
    TOGGLE_SIDENAV = 'TOGGLE_SIDENAV'
}

interface loginActionPending {
    type: AuthActionTypes.LOGIN_PENDING;
}

interface loginActionSuccess {
    type: AuthActionTypes.LOGIN_SUCCESS;
    payload: LoginPayload;
}

interface loginActionFail {
    type: AuthActionTypes.LOGIN_FAIL;
    payload: string | null;
}

export type AuthActions = loginActionFail | loginActionPending | loginActionSuccess;