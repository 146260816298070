import axios from "axios";

const http = axios.create({
  // baseURL: "http://127.0.0.1:8000/api/",
  // baseURL: 'http://65.1.44.82:3000/',
  baseURL: "https://api-prod.thelanguagelake.com/api/",
  headers: {
    "Content-Type": "application/json"
  },
});

http.interceptors.response.use(response => response, error => {
  if (error.response.status === 401 ){
    console.log('Status 403 received, logging out!!!!');
    sessionStorage.setItem('userData', '');
    window.location.href = '/auth/login';
  }
});

export default http;