import { Reducer } from "@reduxjs/toolkit";
import { AuthActions, AuthActionTypes } from "./actions";

export interface AuthState {
    user: any;
    isSidenavOpen: boolean;
    loading: boolean;
    error: string | undefined | null;
}

const initialState: AuthState = {
    user: {},
    isSidenavOpen: true,
    loading: false, 
    error: null 
}

export const authReducer: Reducer<AuthState, any> = (state: AuthState = initialState, action: AuthActions): AuthState => {
    switch(action.type) {
        case AuthActionTypes.LOGIN_PENDING:
            return {
                ...state,
                loading: true 
            } 
        case AuthActionTypes.LOGIN_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                user: action.payload
            }
        // case AuthActionTypes.GET_PROFILE_SUCCESS:
        //     return {
        //         ...state,
        //         loading: false,
        //         profile: action.payload
        //     }
        // case AuthActionTypes.GET_LIMIT_DATA:
        //     return {
        //         ...state,
        //         loading: false,
        //         limit: action.payload
        //     }
        // case AuthActionTypes.TOGGLE_SIDENAV:
        //     return {
        //         ...state,
        //         isSideNavOpen : action.payload 
        //     }
        case AuthActionTypes.LOGIN_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload 
            }
        default: 
            return state;
    }
}