import axiosInstance from "../http-common";

export const setToken = (token: string) => {
    if(token) {
        axiosInstance.defaults.headers.common['Authorization'] = 'Bearer '+token;
    }
    else{
        axiosInstance.defaults.headers.common['Authorization'] = '';
    }
}
