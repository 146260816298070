import { Lead } from "../../types/leads.type";

export enum LeadActionTypes {
    STORE_LEAD = 'STORE_LEAD',
    REMOVE_LEAD = 'REMOVE_LEAD'
}

interface storeLeadAction {
    type: LeadActionTypes.STORE_LEAD;
    payload: Lead;
}

interface removeLeadAction {
    type: LeadActionTypes.REMOVE_LEAD;
    payload: null;
}

export type LeadActions = storeLeadAction | removeLeadAction;