import React, { Suspense, useEffect } from 'react';
import './App.scss';
import { Route, Routes, BrowserRouter as Router } from 'react-router-dom';
import { setToken } from './utils/set-token.utils';
import { useDispatch } from 'react-redux';
import { AuthActionTypes } from './store/auth/actions';
import ErrorBoundary from './components/common/error-boundary/error-boundary';
import { Loader } from './components/common/loader/loader';

const Auth = React.lazy(() => import('./views/auth'));
const Main = React.lazy(() => import('./views/main'));

function App() {
  const userData = JSON.parse(sessionStorage.getItem("userData") || '{}');
  const dispatch = useDispatch();

  useEffect(() => {
    if(userData.access_token) {
      dispatch<any>({
        type: AuthActionTypes.LOGIN_SUCCESS,
        payload: userData
    });
    setToken(userData.access_token);
    }
  }, [userData]);
  
  return (
    <Router>
      <Suspense fallback={<Loader />}>
        <ErrorBoundary>
          <Routes>
            <Route path='auth/*' element={<Auth />} />
            <Route path='/*' element={<Main />} />
          </Routes>
        </ErrorBoundary>
      </Suspense>
    </Router>
  );
}

export default App;
