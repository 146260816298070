import thunk from 'redux-thunk';
import { configureStore } from '@reduxjs/toolkit';
import { authReducer } from './auth/reducer';
import { leadReducer } from './leads/reducer';
import { commonReducer } from './common/reducer';

const reducer = {
  auth: authReducer,
  leadState: leadReducer,
  commonState: commonReducer
}

const store = configureStore({
  reducer,
  middleware: [thunk]
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;